const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/admin'
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      list: `${ROOTS.DASHBOARD}/product/list`,
      add: `${ROOTS.DASHBOARD}/product/add`
    },
    booking: {
      root: `${ROOTS.DASHBOARD}/booking`,
      list: `${ROOTS.DASHBOARD}/booking/list`,
      approve: `${ROOTS.DASHBOARD}/booking/approve`,
      confirm: `${ROOTS.DASHBOARD}/booking/confirm`,
      complete: `${ROOTS.DASHBOARD}/booking/complete`,
      cancel: `${ROOTS.DASHBOARD}/booking/cancel`
    },
    calculate: {
      root: `${ROOTS.DASHBOARD}/calculate`,
      history: `${ROOTS.DASHBOARD}/calculate/history`,
      setting: `${ROOTS.DASHBOARD}/calculate/setting`
    },
    review: {
      root: `${ROOTS.DASHBOARD}/review`,
      list: `${ROOTS.DASHBOARD}/review/list`,
      sns: `${ROOTS.DASHBOARD}/review/sns`
    },
    partner: {
      root: `${ROOTS.DASHBOARD}/partner`,
      info: `${ROOTS.DASHBOARD}/partner/info`,
      contract: `${ROOTS.DASHBOARD}/partner/contract`
    }
  },

  page403: '/403',
  page404: '/404',
  page500: '/500'
};
