import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { UserGuard } from 'src/components/auth-guard/user-guard';
// layouts
import AdminLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// 상품 관리
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductAddPage = lazy(() => import('src/pages/dashboard/product/add'));
const ProductDetailPage = lazy(() => import('src/pages/dashboard/product/detail'));

// 예약 주문 관리
const BookingListPage = lazy(() => import('src/pages/dashboard/booking/booking-list'));
const BookingApproveListPage = lazy(() => import('src/pages/dashboard/booking/booking-approve-list'));
const BookingListAddPage = lazy(() => import('src/pages/dashboard/booking/booking-list-add'));
const BookingListDetailPage = lazy(() => import('src/pages/dashboard/booking/booking-list-detail'));

// 정산 관리
const CalculateHistoryPage = lazy(() => import('src/pages/dashboard/calculate/history'));
const CalculateHistoryDetailPage = lazy(() => import('src/pages/dashboard/calculate/history-detail'));

// 리뷰 관리
const ReviewListPage = lazy(() => import('src/pages/dashboard/review/list'));
const ReviewListDetailView = lazy(() => import('src/pages/dashboard/review/list-detail'));

// 파트너 정보 관리
const PartnerInfoPage = lazy(() => import('src/pages/dashboard/info/edit'));
const PartnerContractInfoPage = lazy(() => import('src/pages/dashboard/info/contract'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      <UserGuard>
        <AdminLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      </UserGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'add', element: <ProductAddPage /> },
          { path: 'detail/:id', element: <ProductDetailPage /> }
        ]
      },
      {
        path: 'booking',
        children: [
          { element: <BookingListPage />, index: true },
          { path: 'list', element: <BookingListPage /> },
          { path: 'approve', element: <BookingApproveListPage /> },
          { path: 'list/add/:id', element: <BookingListAddPage /> },
          { path: 'list/detail/:id', element: <BookingListDetailPage /> }
        ]
      },
      {
        path: 'calculate',
        children: [
          { element: <CalculateHistoryPage />, index: true },
          { path: 'history', element: <CalculateHistoryPage /> },
          { path: 'history/:id', element: <CalculateHistoryDetailPage /> }
        ]
      },
      {
        path: 'review',
        children: [
          { element: <ReviewListPage />, index: true },
          { path: 'list', element: <ReviewListPage /> },
          { path: 'list/:id', element: <ReviewListDetailView /> }
        ]
      },
      {
        path: 'partner',
        children: [
          { element: <PartnerInfoPage />, index: true },
          { path: 'info', element: <PartnerInfoPage /> },
          { path: 'contract', element: <PartnerContractInfoPage /> }
        ]
      }
    ]
  }
];
